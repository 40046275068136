export const routePaths = {
  commonLogin: '/login',
  notFound: '/a/p/app/404',
  login: '/a/login',
  loginCallback: '/a/login-callback',
  dashboard: '/a/p/app/dashboard',
  tokensManagement: '/a/p/app/tokens-management',
  emailTemplates: '/a/p/app/email-templates',
  protectedHome: '/a/p',
  business: (id: string) => `/a/p/app/business/${id}`,
};
